// Libraries
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Utilities
import { classicTheme, storyTheme } from 'common/utils/helpers';

const SocialLink = ({ title, href, iconClass }) => {
  return (
    <li>
      <a
        title={title}
        aria-label={title}
        href={href}
        className={classNames({
          'has-text-white': classicTheme(),
          'is-secondary-color': storyTheme(),
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon is-large">
          <i className={iconClass} />
        </span>
      </a>
    </li>
  );
};

SocialLink.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
};

export default SocialLink;
