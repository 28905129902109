// Libraries
import React from 'react';
import { currentLanguage } from 'i18n';
import _ from 'lodash';

const locale = _.camelCase(window.gon.defaultLanguage || currentLanguage);

const Logo = () => (
  <div className="logo">
    {gon.brand.logo_url ? (
      <a
        href={gon.brand.alternative_homepage_url || `/${locale}`}
        title={gon.brand.name_translations[currentLanguage]}
        aria-label={gon.brand.name_translations[currentLanguage]}
      >
        <img alt={gon.brand.name_translations[currentLanguage]} src={gon.brand.logo_url} />
      </a>
    ) : (
      <div>
        <i className="fab fa-pagelines" />
        Our City Logo
      </div>
    )}
  </div>
);

export default Logo;
