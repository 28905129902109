import React from 'react';

const NextDoorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="100%"
    height="100%"
    viewBox="0 0 30 30"
    style={{ zoom: 1 }}
  >
    <title>Nextdoor</title>
    <path
      fill="#fcfcfc"
      d="M17.059,9.928 c-2.2,0 -4.075,1.009 -5.128,2.556 c-0.088,0.128 -0.238,0.403 -0.438,0.406 c-0.963,0.006 -1.022,-1.178 -1.053,-2.238 c-0.003,-0.156 -0.137,-0.284 -0.294,-0.284 l-2.787,-0.016 c-0.166,0 -0.3,0.137 -0.297,0.3 c0.063,2.584 0.537,4.341 3.669,5.041 c0.172,0.037 0.291,0.194 0.291,0.369 c0,1.075 0,4.741 0,5.806 c0,0.163 0.131,0.294 0.294,0.294 h2.706 c0.162,0 0.294,-0.131 0.294,-0.294 v-5.997 c0,-1.331 0.944,-2.844 2.738,-2.844 c1.706,0 2.738,1.512 2.738,2.844 v5.997 c0,0.163 0.131,0.294 0.294,0.294 h2.706 c0.163,0 0.294,-0.131 0.294,-0.294 v-6.441 c0.006,-3.081 -2.622,-5.5 -6.025,-5.5 "
      visibility="visible"
    />
  </svg>
);

export default NextDoorIcon;
