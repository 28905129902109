// Libraries
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function useGoogleTracking(trackingIds = []) {
  const { listen } = useHistory();

  useEffect(
    () =>
      listen((location) => {
        for (const id of trackingIds) {
          window.gtag('config', id, { page_path: location.pathname });
        }
      }),
    [trackingIds, listen]
  );
}
