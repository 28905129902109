// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import {
  facebookShareUrl,
  twitterShareUrl,
  emailShareUrl,
  linkedinShareUrl,
  nextdoorShareLink,
} from 'common/utils/helpers';

// Components
import NextDoorIcon from 'common/components/NextDoorIcon';

const SocialShareLinks = ({ className }) => {
  const url = document.querySelector('meta[property="og:url"]')?.getAttribute('content');
  const title = document.querySelector('meta[property="og:title"]')?.getAttribute('content');

  const facebookUrl = facebookShareUrl(url);
  const twitterUrl = twitterShareUrl(title, url);
  const linkedinUrl = linkedinShareUrl(url);
  const emailUrl = emailShareUrl(title, url);
  const nextDoorUrl = nextdoorShareLink(url, title);

  return (
    <>
      <a
        href={nextDoorUrl}
        className={classNames('has-text-white', className)}
        target="_blank"
        rel="noopener noreferrer"
        title="Nextdoor"
        aria-label="Nextdoor"
        style={{ lineHeight: '1', fontSize: '1px' }}
      >
        <span className="icon is-medium">
          <NextDoorIcon />
        </span>
      </a>
      <a
        href={facebookUrl}
        className={classNames('has-text-white', className)}
        target="_blank"
        rel="noopener noreferrer"
        title="Facebook"
        aria-label="Facebook"
      >
        <span className="icon is-medium">
          <i className="fab fa-lg fa-facebook-f" />
        </span>
      </a>
      <a
        href={twitterUrl}
        className={classNames('has-text-white', className)}
        target="_blank"
        rel="noopener noreferrer"
        title="Twitter"
        aria-label="Twitter"
      >
        <span className="icon is-medium">
          <i className="fab fa-lg fa-x-twitter" />
        </span>
      </a>
      <a
        href={linkedinUrl}
        className={classNames('has-text-white', className)}
        target="_blank"
        rel="noopener noreferrer"
        title="LinkedIn"
        aria-label="LinkedIn"
      >
        <span className="icon is-medium">
          <i className="fab fa-lg fa-linkedin" />
        </span>
      </a>
      <a
        href={emailUrl}
        className={classNames('has-text-white', className)}
        title="E-mail"
        aria-label="E-mail"
      >
        <span className="icon is-medium">
          <i className="fas fa-lg fa-envelope" />
        </span>
      </a>
    </>
  );
};

SocialShareLinks.propTypes = {
  className: PropTypes.string,
};

SocialShareLinks.defaultProps = {
  className: null,
};

export default SocialShareLinks;
