// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';

/* eslint-disable react/jsx-one-expression-per-line */
export const NotFoundPageContainer = ({ t }) => (
  <div className="hero is-primary is-fullheight is-primary-background not-found-page">
    <div className="hero-body">
      <div className="container">
        <div className="hero-content">
          <h1 className="title is-1 error-text">{t('common.404_error_message')}</h1>
          <p className="subtitle is-4 error-text">
            <Trans i18nKey="common.page_not_found_error_message">
              The page you requested cannot be found, please <a href="/">click here</a> to return
              Home.
            </Trans>
          </p>
        </div>
      </div>
    </div>
  </div>
);
/* eslint-enable react/jsx-one-expression-per-line */

NotFoundPageContainer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NotFoundPageContainer);
