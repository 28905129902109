// Libraries
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// Components
import SocialShareLinks from 'common/components/SocialShareLinks';

const SocialShareToolbar = () => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const toggleExpanded = () => setExpanded((currentValue) => !currentValue);

  return (
    <div
      className={classNames('social-share-toolbar', {
        'social-share-toolbar--expanded': expanded,
      })}
    >
      <button
        type="button"
        className="social-share-toolbar__title"
        onClick={toggleExpanded}
        title={t('common.share')}
      >
        {t('common.share')}
      </button>
      <SocialShareLinks className="social-share-toolbar__link" />
    </div>
  );
};

export default SocialShareToolbar;
