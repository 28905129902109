// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { urlFormatter } from 'common/utils/helpers';

// Components
import SocialLink from 'common/components/SocialLink';

const SocialFooter = ({ project: { facebookUrl, twitterUrl, contactEmail } }) => (
  <div className="social-footer">
    <div className="container inner">
      <ul className="social-nav">
        {facebookUrl && (
          <SocialLink
            title="Facebook"
            href={urlFormatter(facebookUrl)}
            iconClass="fab fa-2x fa-facebook"
          />
        )}
        {twitterUrl && (
          <SocialLink
            title="Twitter"
            href={urlFormatter(twitterUrl)}
            iconClass="fab fa-2x fa-square-x-twitter"
          />
        )}
        {contactEmail && (
          <SocialLink
            title="E-mail"
            href={`mailto:${contactEmail}`}
            iconClass="fa fas fa-2x fa-envelope"
          />
        )}
      </ul>
    </div>
  </div>
);

SocialFooter.propTypes = {
  project: PropTypes.shape({
    facebookUrl: PropTypes.string,
    twitterUrl: PropTypes.string,
    contactEmail: PropTypes.string,
  }),
};

SocialFooter.defaultProps = {
  project: {
    facebookUrl: null,
    twitterUrl: null,
    contactEmail: null,
  },
};

export default SocialFooter;
