// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utils
import { storyTheme } from 'common/utils/helpers';

export const Footer = ({ children, t }) => (
  <footer className="footer">
    {children}
    <div className="footer-elements container inner">
      <div className="footer-elements__container">
        <ul className="footer__utility-nav">
          <li>&copy; {`${new Date().getFullYear()} ${gon.whitelabelName || 'Zencity Engage'}`}</li>
          <li>
            <a
              href="https://zencity.io/privacy-policy/"
              className="is-secondary-color"
              target="_blank"
              rel="noreferrer"
            >
              {t('common.privacy_policy')}
            </a>
          </li>
          <li>{import.meta.env.VITE_CIVIL_SPACE_VERSION}</li>
        </ul>
        <div className="footer__zencity-powered">
          {t('common.powered_by')}
          <img
            src={`https://logo.zencity.io/logo${storyTheme() ? '-light' : ''}.svg`}
            alt="zencity logo"
          />
        </div>
      </div>
      <div id="exit-links" />
    </div>
  </footer>
);

Footer.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
};

Footer.defaultProps = {
  children: null,
};

export default withTranslation()(Footer);
