/**
 * Bulma's media queries
 */
export default Object.freeze({
  smallDevices: {
    maxWidth: 480,
  },
  mobile: {
    maxWidth: 768,
  },
  tablet: {
    maxWidth: 1023,
  },
  desktop: {
    minWidth: 1024,
  },
  widescreen: {
    minWidth: 1216,
  },
  fullhd: {
    minWidth: 1408,
  },
});
