// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utils
import { redirectToSignInPath } from 'common/utils/authHelpers';

const HeaderSignIn = ({ t }) => (
  <div className="auth-container">
    <p className="auth-text">{t('common.sign_in_to_participate')}</p>
    <button
      type="button"
      className="button is-secondary-color is-header"
      onClick={() => redirectToSignInPath(window.location.pathname)}
    >
      {t('ideation.sign_in_button')}
    </button>
  </div>
);

HeaderSignIn.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(HeaderSignIn);
