// Libraries
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { defaultLanguage, LANGUAGES } from 'i18n';

// Routes
import Routes from 'routes';

// Utilities
import { populatePath } from 'common/utils/helpers';

function LocaleSelector({ locales, meta }) {
  const match = useRouteMatch();
  const params = useParams();
  const location = useLocation();
  const selectRef = useRef();
  const [width, setWidth] = useState(null);
  const [value, setValue] = useState(
    locales.find((locale) => locale === params.locale) || locales[0]
  );

  function onChange(event) {
    const locale = event.currentTarget.value;
    const path = meta?.preview ? Routes.ENGAGEMENT_PREVIEW : match.path;
    const url = populatePath(path, { ...params, locale });
    setWidth(null);
    setValue(locale);
    window.location.assign(url + location.search);
  }

  function renderOptions() {
    if (width === null) return <option value={value}>{LANGUAGES[value].localTitle}</option>;

    return locales.map((locale) => (
      <option value={locale} key={locale}>
        {LANGUAGES[locale].localTitle}
      </option>
    ));
  }

  useEffect(() => {
    // Add setTimeout to fix some fonts rendering
    setTimeout(() => {
      setWidth(selectRef.current?.getBoundingClientRect().width);
    }, 500);
  }, [width]);

  return (
    <div className="locale-selector">
      <span className="sr-only">Language Selector</span>
      <i className="fas fa fa-globe" />
      <select
        value={value}
        onChange={onChange}
        style={{ width: width ? `${width}px` : undefined }}
        ref={selectRef}
        aria-label={'Language Selector'}
      >
        {renderOptions()}
      </select>
    </div>
  );
}

LocaleSelector.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape({
    preview: PropTypes.bool,
  }),
};

LocaleSelector.defaultProps = {
  locales: [defaultLanguage],
  meta: { preview: false },
};

export default LocaleSelector;
