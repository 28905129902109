// Libraries
import React from 'react';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

// Utils
import { getUserToken } from 'ideation/utils/userAuthenticate';
import { csrfToken, signOutPath } from 'common/utils/authHelpers';

const SIGN_OUT_PATH = signOutPath(window.location.pathname);
const CSRF_TOKEN = csrfToken();

/* eslint-disable react/jsx-one-expression-per-line */
const HeaderSignOut = ({ t }) => {
  const token = getUserToken();
  const username = jwtDecode(token).preferred_username;
  return (
    <div className="auth-container">
      <p className="auth-text">
        <Trans i18nKey="common.signed_in_as" username={username}>
          Signed in as <span className="auth-name">{{ username }}</span>
        </Trans>
      </p>
      <form action={SIGN_OUT_PATH} method="POST">
        <input type="hidden" name="authenticity_token" value={CSRF_TOKEN} />
        <input type="hidden" name="_method" value="delete" />
        <button type="submit" className="button is-secondary-color is-header">
          {t('common.sign_out')}
        </button>
      </form>
    </div>
  );
};
/* eslint-enable react/jsx-one-expression-per-line */

HeaderSignOut.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(HeaderSignOut);
