// Libraries
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

// Utilities
import { isEmbedded } from 'common/utils/helpers';
import { userAuthenticated } from 'ideation/utils/userAuthenticate';
import { defaultLanguage } from 'i18n';

// Routes
import Routes from 'routes';

// Components
import HeaderSignIn from 'header/components/HeaderSignIn';
import HeaderSignOut from 'header/components/HeaderSignOut';
import LocaleSelector from 'common/components/LocaleSelector';
import Logo from 'common/components/Logo';
import SocialShareToolbar from 'common/components/SocialShareToolbar';

const Header = ({
  meta,
  engagement,
  project,
  ideaBoard,
  projectHub,
  isLastSection,
  discussionTopic,
  showAuth,
}) => {
  // Only display locale selector if additional locale options exist
  const supportedLanguages =
    engagement?.languages ||
    project?.languages ||
    ideaBoard?.languages ||
    discussionTopic?.languages ||
    gon.brand.languages;
  const renderLocaleSelector = supportedLanguages?.some((language) => language !== defaultLanguage);
  const userAuthHeader = userAuthenticated() ? <HeaderSignOut /> : <HeaderSignIn />;

  return (
    <header className={classNames('header', { 'header--project-hub': projectHub })}>
      <div className="container inner">
        <div className="header-items">
          <div className="header-item">{!isEmbedded() && <Logo />}</div>
          {renderLocaleSelector && (
            <div className="header-item">
              <LocaleSelector locales={supportedLanguages} meta={meta} />
            </div>
          )}
          {showAuth && <div className="header-item">{userAuthHeader}</div>}
        </div>
      </div>
      <Switch>
        <Route
          path={Routes.ENGAGEMENT}
          render={() =>
            engagement.visible &&
            engagement.project.socialSharing &&
            isLastSection && <SocialShareToolbar />
          }
        />
        <Route
          path={[Routes.IDEA_BOARD, Routes.IDEA]}
          render={() =>
            ideaBoard.visible && ideaBoard.project.socialSharing && <SocialShareToolbar />
          }
        />
        <Route
          path={[Routes.DISCUSSION_TOPIC]}
          render={() =>
            discussionTopic.visible &&
            discussionTopic.project.socialSharing && <SocialShareToolbar />
          }
        />
        <Route
          path={[Routes.PROJECT, Routes.ANNOUNCEMENT, Routes.ENGAGEMENT_REPORT]}
          render={() => project.socialSharing && <SocialShareToolbar />}
        />
      </Switch>
    </header>
  );
};

Header.defaultProps = {
  projectHub: false,
  engagement: {},
  project: {},
  ideaBoard: {},
  discussionTopic: {},
  isLastSection: null,
  meta: { preview: false },
  showAuth: false,
};

Header.propTypes = {
  projectHub: PropTypes.bool,
  engagement: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.string),
    redirectUrl: PropTypes.string,
    visible: PropTypes.bool,
    project: PropTypes.shape({
      socialSharing: PropTypes.bool,
    }),
  }),
  project: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.string),
    socialSharing: PropTypes.bool,
  }),
  ideaBoard: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.string),
    visible: PropTypes.bool,
    project: PropTypes.shape({
      socialSharing: PropTypes.bool,
    }),
  }),
  discussionTopic: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.string),
    visible: PropTypes.bool,
    project: PropTypes.shape({
      socialSharing: PropTypes.bool,
    }),
  }),
  isLastSection: PropTypes.bool,
  meta: PropTypes.shape({
    preview: PropTypes.bool,
  }),
  showAuth: PropTypes.bool,
};

export default Header;
